import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cityId:"1"
  },
  mutations: {//同步操作，实例：this.$store.commit('SET_SOCKET',3)，
    "SET_cityId":(state,socket)=>{ //此处必须传入一个state，后面是调用传入的值
      state.cityId = socket
    }
  },
  actions: {
  },
  modules: {
  }
})
