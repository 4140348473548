<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
.sosuo{
  position: absolute;
  left:50%;
  margin-left: -384px;
  margin-top: 22px;
  z-index: 99999;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  /*margin: 0;*/
  /*padding: 0;*/

}
/**{margin: 0;padding: 0}*/
.hide{
  opacity:0;
}
.left{float: left}
.right{
  float: right;}
.map{
  height: 1080px;
  width: 100%;
  position: absolute;
  z-index: -2;
}
.map2{
  height: 1080px;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.quan{
  height: 1080px;
  width: 1920px;
  z-index: 9999999;
  margin: 0 auto;
}
.lanBg{background-color: #0b2043;
  height: 1080px;
  width: 100%;
  opacity:0.85;
  position:fixed;
  z-index: -1;
}
main_content{z-index: 999}
.header{
  background: url("./assets/head.png") no-repeat center;
  height: 77px;
  position: relative;
  z-index: 999;
}
</style>
