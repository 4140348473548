import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'
Vue.use(Router)

export const constantRoutes = [
  {
    path: '/test',
    component: () => import('@/views/test'),
    hidden: true
  },
  {
    path: '/',
    name: '/yunHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunHome.vue'),
    meta: { title: "云享药房" }
  }, {
    path: '/cityMap',
    name: 'cityMap',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/cityMap.vue'),
    meta: { title: "地图" }

  }, {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: { title: "金药匙·云享药房" }

  }, {
    path: '/szqy',
    name: 'szqy',
    component: () => import(/* webpackChunkName: "about" */ '../views/szqy.vue'),
    meta: { title: "数字可视化大屏" }

  },
  {
    path: '/szjg',
    name: 'szjg',
    component: () => import(/* webpackChunkName: "about" */ '../views/szjg.vue'),
    meta: { title: "数字可视化大屏" }
  },
  {
    path: '/szjgdd',
    name: 'szjgdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/szjgdd.vue'),
    meta: { title: "数字可视化大屏" }
  },
  {
    path: '/szms',
    name: 'szms',
    component: () => import(/* webpackChunkName: "about" */ '../views/szms.vue'),
    meta: { title: "数字可视化大屏" }
  },
  {
    path: '/yqyp',
    name: 'yqyp',
    component: () => import(/* webpackChunkName: "about" */ '../views/yqyp.vue'),
    meta: { title: "数字可视化大屏" }
  },
  {
    path: '/intro',
    name: 'pujiang',
    component: () => import(/* webpackChunkName: "about" */ '../views/pujiang2.vue'),
    meta: { title: "浦江县零售药店全场景智控系统" }
  },
  {
    path: '/intro2',
    name: 'pujiang2',
    component: () => import(/* webpackChunkName: "about" */ '../views/pujiangm.vue'),
    meta: { title: "浦江县零售药店全场景智控系统" }
  },
  {
    path: '/cloudHome',
    name: 'cloudHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloudHome.vue'),
    meta: { title: "智控云药房" }
  },
  {
    path: '/cloudBuy',
    name: 'cloudBuy',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloudBuy.vue'),
    meta: { title: "智控云药房" }
  },
  {
    path: '/cloudFind',
    name: 'cloudFind',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloudFind.vue'),
    meta: { title: "智控云药房" }
  }
  ,
  {
    path: '/cloudUse',
    name: 'cloudUse',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloudUse.vue'),
    meta: { title: "智控云药房" }
  }
  ,
  {
    path: '/cloudWit',
    name: 'cloudWit',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloudWit.vue'),
    meta: { title: "智控云药房" }
  }
  ,
  {
    path: '/smartHome',
    name: 'smartHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartHome.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartBuy',
    name: 'smartBuy',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartBuy.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartFind',
    name: 'smartFind',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartFind.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartUse',
    name: 'smartUse',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartUse.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartWit',
    name: 'smartWit',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartWit.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartDynamic',
    name: 'smartDynamic',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartDynamic.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartRisk',
    name: 'smartRisk',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartRisk.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartGrading',
    name: 'smartGrading',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartGrading.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/smartXuan',
    name: 'smartXuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/smartXuan.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunRegulation', //精密监管
    name: 'yunRegulation',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunRegulation.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunMedicine', //便捷买药
    name: 'yunMedicine',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunMedicine.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunAssess', //考核评级
    name: 'yunAssess',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunAssess.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunWarning', //预警分析
    name: 'yunWarning',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunWarning.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunRisk', //处置分析
    name: 'yunRisk',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunRisk.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunEnterprise', //企业经营资质及设备管理
    name: 'yunEnterprise',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunEnterprise.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunAwardedMarks', //药店加分管理
    name: 'yunAwardedMarks',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunAwardedMarks.vue'),
    meta: { title: "云享药房" }
  },
  {
    path: '/yunEpidemic', //药店防疫管理
    name: 'yunEpidemic',
    component: () => import(/* webpackChunkName: "about" */ '../views/yunEpidemic.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunStore',
    name: 'yunStore',
    component: () => import('../views/yunStore.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunHome',
    name: 'yunHome',
    component: () => import('../views/yunHome.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunYaoshi',
    name: 'yunYaoshi',
    component: () => import('../views/yunYaoshi.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunUse',
    name: 'yunUse',
    component: () => import('../views/yunUse.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunControl', //今日风险管控
    name: 'yunControl',
    component: () => import('../views/yunControl.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunDrugControl', //药品管控管理
    name: 'yunDrugControl',
    component: () => import('../views/yunDrugControl.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunStockSell', //进销存动态分析
    name: 'yunStockSell',
    component: () => import('../views/yunStockSell.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/yunSanji', //三级找药
    name: 'yunSanji',
    component: () => import('../views/yunSanji.vue'),
    meta: { title: "云享药房" }
  }
  ,
  {
    path: '/ppt', //三级找药
    name: 'ppt',
    component: () => import('../views/ppt/index'),
    meta: { title: "云享药房" }
  }
]

const createRouter = () => new Router({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach(async (to, from, next) => {
  let host = window.location.hostname
  if (to.name == "login" || Cookies.get("AuthorizationToken") || host == "192.168.1.113" || to.name == "pujiang2" || to.name == "pujiang") {
    next()
  } else {
    router.push("/login??url=" + to.path)
  }

})

export default router
